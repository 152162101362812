import React from "react";
import Job from "../components/job";
import { Jumbotron, Container, Row, Col, Image, Button } from "react-bootstrap";
import Header from "../components/header";
import Layout from "../components/Layout";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  hero,
  fullwidthtitle1,
  twoColumnTextImage1,
  mainpitch,
  twoColumnImageText,
  fourCol,
  fourStack,
  form,
  description,
  intro
}) => <div></div>;

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Container>
        <Row className="show-Container">
          <Col xs={12}>
            <Header
              title="Richard England-Lee"
              text="I'm a Digital Designer based in the UK. Throughout my career I've been lucky enough to have worked on some incredible projects across some fantastic brands. I have experience working in a broad range of industries, including eCommerce, Health & Beauty, Finance and Entertainment.
<br /><br />
              This site was built using <b>React</b> and is a collection of projects that showcase my skills in <b>UI, UX, motion, branding design</b> and <b>code</b>."
            />
          </Col>

          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/superdrug-mobile-card.jpg"}
              jobType="Print | Branding | Animation | Packaging Design "
              title="Superdrug Mobile"
              text="Creating a mobile brand from scratch"
              url="/superdrug-mobile/"
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/cappfinity-card.jpg"}
              jobType="UI | UX | Front End Dev"
              title="Cappfinity"
              text="World leaders in strengths-based assessment"
              url="/cappfinity/"
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/marionnaud-card3.jpg"}
              jobType="UI | UX | Web Design "
              title="Marionnaud"
              text="A European Health & Beauty brand"
              url="/marionnaud/"
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/mlife-card3.jpg"}
              jobType="UI | UX | Web Design "
              title="M Life"
              text="An inspirational blog for Marionnaud"
              url="/mlife/"
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/superdrug-card2.jpg"}
              jobType="Web Design "
              title="Superdrug"
              text="A Health & Beauty specialist based in the UK"
              url="/superdrug/"
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/spread-co-card.jpg"}
              jobType="UI | UX | Web Design | Print | Front End Dev"
              title="Spread Co"
              text="A financial spread betting company"
              url="/spread-co/"
            />
          </Col>

          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/perfumeshop-card.jpg"}
              jobType="Animation"
              title="The Perfume Shop"
              text="A fragrance specialist based in the UK"
              url="/perfume-shop/"
            />
          </Col>

          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/vermaak-card.jpg"}
              title="Christian Vermaak"
              jobType="Web Design "
              text="Celebrity hair and make up artist"
              url="/christian-vermaak/"
            />
          </Col>
          <Col xs={12} sm={6} md={6}>
            <Job
              image={"/img/smiial-card2.jpg"}
              jobType="UI | UX | Web Design "
              title="Smiial"
              text="A platform designed for sharing"
              url="/smiial/"
            />
          </Col>

          <Col xs={12} sm={6} md={6}></Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default IndexPage;
